import { useForm, watch } from 'react-hook-form';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../util/index';

import HeaderOld from '../components/HeaderOld';
import { registraionApi } from '../util/envConfig';
import EyeIcon from '../icons/EyeIcon';
import EyeHideIcon from '../icons/EyeHideIcon';

const UserRegistration = () => {
	const [errMsg, setErrMsg] = useState('');
	const [selectedCollege, setSelectedCollege] = useState('');
	const [selectedUniversity, setSelectedUniversity] = useState('');
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [isOtherUniversity, setIsOtherUniversity] = useState(false);
	const [isOtherCollege, setIsOtherCollege] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const universityOptions = [
		'University of Agriculture Sciences, Bangalore',
		'GB Pant University',
		'University of horticultural sciences, Bagalkot',
		'Career Point University',
		'Lovely Professional University',
		'Vellore Institute of Technology',
		'University of Horticulture Sciences',
		'Institute of Plantation Management',
	    'XIM University',
		'College of Agriculture Madhav University',
		'Parul University',
		'Dr. Rajendra Prasad Central Agricultural University',
		'Utkal University',
		'SHUATS University',
		'School of Agriculture Science'
	];
	const universityCollegeData = {
		'University of Agriculture Sciences, Bangalore': [
			'College of Agriculture - Chamarajanagara',
			'College of Agriculture - Mandya',
			'Gandhi Krishi, Vignana Kendra, Bengaluru',
			'College of Agriculture - Hassan',
			'College of sericulture - Chintamani',
		],
		'GB Pant University': ['College of Agriculture, Pantnagar'],
		'University of horticultural sciences, Bagalkot': [
		'Horticulture college, GKVK, Bangalore'],
		'Career Point University': ['Career Point University'],
		'Lovely Professional University': ['Lovely Professional University,Punjab'],
		'Vellore Institute of Technology':['Vellore Institute of Technology, Vellore, Tamil Nadu'],
		'University of Horticulture Sciences':['University of Horticulture Sciences, Bagalkot'],
		'Institute of Plantation Management':['Institute of Plantation Management, Bangalore'],
		'XIM University':['XIM University, Bhubaneshwar, Odisha'],
		'College of Agriculture Madhav University': ['College of Agriculture Madhav University,Rajasthan'],
		'Parul University':['Parul University,Gujarat'],
		'Dr. Rajendra Prasad Central Agricultural University':['Dr. Rajendra Prasad Central Agricultural University,Bi'],
		'Utkal University':['Utkal University, Bhubaneshwar Odisha'],
		'SHUATS University':['SHUATS University, Prayagraj'],
		'School of Agriculture Science':['School of Agriculture Science,Janardhan Rai Nagar Rajasthan Vidyapeeth']
	};

	const onSubmit = async (data) => {
		try {
			const requestData = {
				universityName: isOtherUniversity
					? data.otherUniversity
					: data.universityName,
				collegeName: isOtherCollege ? data.otherCollege : data.collegeName,
				username: data.email,
				password: data.password,
				studentID: data.studentId,
				// email: data.email,
				phone: data.phoneNumber,
				firstName: data.fullName,
				lastName: '',
				salutation: '',
			};

			const response = await axios.post(registraionApi, requestData);
			const responseData = response.data;
			if (responseData) {
				showToast('New User Succesfully Added', 'success');
				navigate('/login');
			}
		} catch (error) {
			showToast(error?.response?.data?.error, 'error');
			console.error('Error while User Registration:', error);
		}
	};

	return (
		<div>
			{/* <section className="w-full min-h-screen overflow-hidden bg-gradient-to-tr from-sfPrimaryColor via-sfPrimaryColor to-sfGradientEndGreenColor flex flex-row justify-center items-center px-10"> */}
			<section className="w-full lg:h-screen overflow-hidden bg-gradient-to-tr from-footerColor2 to-[#daf2d8] 123to-[#59cfd3] flex flex-col px-4 lg:px-0 pb-4 lg:pb-0">
				<div className="mt-4 lg:ml-5 mb-5">
					<img src="bighaat-white-logo.png" className="w-[140px] h-[52px]" />
				</div>
				<div className="w-full lg:w-[85%] bg-white rounded-3xl p-5 mx-auto shadow-2xl h-[80%] flex flex-col lg:flex-row">
					<div className="w-full md:w-7/12 flex justify-center items-center mb-5 lg:mb-0">
						<img
							src="registration-image.png"
							className="w-[300px] h-[300px] lg:w-[400px] lg:h-[400px]"
						/>
					</div>
					<div className="w-full md:w-1/12"></div>
					<div className="w-full md:w-4/12">
						<div className="w-full bg-sectionColor1 p-4 rounded-2xl h-full 123shadow-2xl overflow-y-auto scroll-smooth scrollbar">
							<h3 className="text-2xl font-semibold text-black mb-5">
								Registration
							</h3>
							<form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
								{errMsg && (
									<p className="text-RedOrange font-normal py-2 text-sm">
										{errMsg}
									</p>
								)}

								<div className="mb-5">
									<label
										htmlFor="fullName"
										className="block text-sm font-normal text-disableText"
									>
										Full Name
										<span style={{ color: 'red' }}>*</span>
										<input
											type="text"
											id="fullName"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											autoComplete="off"
											{...register('fullName', {
												required: true,
											})}
											required
										/>
									</label>
									{errors.fullName && errors.fullName.type === 'required' && (
										<p className="errMsg">Name is required.</p>
									)}
								</div>
								<div className="mb-5">
									<label
										htmlFor="phoneNumber"
										className="block text-sm font-normal text-disableText"
									>
										Mobile Number
										<span style={{ color: 'red' }}>*</span>
										<input
											type="tel"
											id="phoneNumber"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											autoComplete="off"
											{...register('phoneNumber', {
												pattern: {
													value: /^[0-9]{10}$/,
													message: 'Invalid phone number format',
												},
												required: true,
											})}
											required
										/>
									</label>
									{errors.phoneNumber &&
										errors.phoneNumber.type === 'required' && (
											<p className="errMsg">Mobile Number is required.</p>
										)}
									{errors.phoneNumber &&
										errors.phoneNumber.type === 'pattern' && (
											<p className="errMsg">Mobile Number is not valid.</p>
										)}
								</div>

								<div className="mb-5">
									<label
										htmlFor="email"
										className="block text-sm font-normal text-disableText"
									>
										Email / User Name
										<span style={{ color: 'red' }}>*</span>
										<input
											type="email"
											id="email"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											//   ref={fieldRef}
											autoComplete="off"
											{...register('email', {
												required: true,
												pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
											})}
											required
										/>
									</label>
									{errors.email && errors.email.type === 'required' && (
										<p className="errMsg">Email is required.</p>
									)}
									{errors.email && errors.email.type === 'pattern' && (
										<p className="errMsg">Email is not valid.</p>
									)}
								</div>

								<div className="mb-5">
									<label
										htmlFor="password"
										className="block text-sm font-normal text-disableText"
									>
										Password
										<span style={{ color: 'red' }}>*</span>
										<input
											id="password"
											type="password"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											{...register('password', {
												required: true,
												minLength: 4,
											})}
											autoComplete="off"
											required
										/>
									</label>
									{errors.password && errors.password.type === 'required' && (
										<p className="errMsg">Password is required.</p>
									)}
									{errors.password && errors.password.type === 'minLength' && (
										<p className="errMsg">
											Password should be at-least 4 characters.
										</p>
									)}
								</div>
								<div className="mb-5">
									<label
										htmlFor="confirmPassword"
										className="block text-sm font-normal text-disableText"
									>
										Confirm Password
										<span style={{ color: 'red' }}>*</span>
										<div className="relative flex items-center">
											<input
												id="confirmPassword"
												type={showConfirmPassword ? 'text' : 'password'}
												className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
												{...register('confirmPassword', {
													required: true,
													validate: (value) => value === watch('password'),
												})}
												autoComplete="off"
												required
											/>

											<button
												type="button"
												className="absolute right-3 top-[14px]"
												onClick={() =>
													setShowConfirmPassword(!showConfirmPassword)
												}
											>
												{showConfirmPassword ? <EyeHideIcon /> : <EyeIcon />}
											</button>
										</div>
									</label>
									{errors.confirmPassword &&
										errors.confirmPassword.type === 'required' && (
											<p className="errMsg">Confirm Password is required.</p>
										)}
									{errors.confirmPassword &&
										errors.confirmPassword.type === 'validate' && (
											<p className="errMsg">Passwords do not match.</p>
										)}
								</div>

								<div className="mb-5">
									<label className="block text-sm font-normal text-disableText">
										Student Id
										<span style={{ color: 'red' }}>*</span>
										<input
											type="text"
											id="studentId"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											//   ref={fieldRef}
											autoComplete="off"
											{...register('studentId', {
												required: true,
											})}
											required
										/>
									</label>
									{errors.studentId && errors.studentId.type === 'required' && (
										<p className="errMsg">Student Id is required.</p>
									)}
								</div>

								<div className="mb-5">
									<label
										htmlFor="universityName"
										className="block text-sm font-normal text-disableText"
									>
										University Name
										<span style={{ color: 'red' }}>*</span>
										<select
											id="universityName"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											{...register('universityName', {
												required: true,
											})}
											value={selectedUniversity}
											onChange={(e) => {
												setSelectedUniversity(e.target.value);
												setIsOtherUniversity(e.target.value === 'Others');
												setSelectedCollege('');
											}}
										>
											<option value="">Select University</option>
											{universityOptions.map((university, index) => (
												<option key={index} value={university}>
													{university}
												</option>
											))}
											<option value="Others">Others</option>
										</select>
									</label>
									{errors.universityName &&
										errors.universityName.type === 'required' && (
											<p className="errMsg">University Name is required.</p>
										)}

									<div className="mb-2">
										{isOtherUniversity && (
											<input
												type="text"
												id="otherUniversity"
												className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
												{...register('otherUniversity', {
													required: true,
												})}
												placeholder="Enter University Name"
											/>
										)}
									</div>
								</div>
								<div className="mb-5">
									<label
										htmlFor="collegeName"
										className="block text-sm font-normal text-disableText"
									>
										College Name
										<span style={{ color: 'red' }}>*</span>
										<select
											id="collegeName"
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											{...register('collegeName', {
												required: true,
											})}
											value={selectedCollege}
											onChange={(e) => {
												setSelectedCollege(e.target.value);
												setIsOtherCollege(e.target.value === 'Others');
											}}
										>
											<option value="">Select College</option>
											{universityCollegeData[selectedUniversity] &&
											selectedUniversity
												? universityCollegeData[selectedUniversity].map(
														(college, index) => (
															<option key={index} value={college}>
																{college}
															</option>
														)
												  )
												: null}
											<option value="Others">Others</option>
										</select>
									</label>
									{errors.collegeName &&
										errors.collegeName.type === 'required' && (
											<p className="errMsg">College Name is required.</p>
										)}
									<div className="mb-2">
										{isOtherCollege && (
											<input
												type="text"
												id="otherCollege"
												className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
												{...register('otherCollege', {
													required: true,
												})}
												placeholder="Enter College Name"
											/>
										)}
									</div>
								</div>

								<div className="mt-8">
									<button
										type="submit"
										className="w-full px-3 py-2.5 text-sm font-medium text-white transition-colors duration-200 transform bg-sfPrimaryColor rounded-md hover:bg-sfSecondaryColor focus:outline-none focus:bg-sfSecondaryColor tracking-wider"
									>
										SUBMIT
									</button>
								</div>
								<div className="flex flex-row items-center justify-center mt-1.5">
									<p className="text-sm font-medium text-grayColor mr-2">
										Already registered?
									</p>
									<a
										href="/"
										target="_self"
										className="text-sm font-medium text-sfSecondaryColor"
									>
										Login
									</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default UserRegistration;
